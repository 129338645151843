import { useState, useEffect, useRef } from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { FloatingWhatsApp } from 'react-floating-whatsapp'

import Home from './Home'
import Partners from './Partners'
import Us from './Us'
import Suscribe from './Suscribe'
import Gallery from './Gallery'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import { Facebook, Instagram } from 'react-bootstrap-icons'
import logo from './img/logo.png'
import logo_b from './img/logo_b.png'
import logoWhats from './img/trayecto.png'

function App() {
  const [ path, setPath ] = useState('')

  const refHome = useRef()
  const refUs = useRef()
  const refPartners = useRef()
  const refJoinUs = useRef()
  const refGallery = useRef()

  const handleClick = (eventKey) => {

    var element = eval(eventKey).current;
    var headerOffset = 86;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });

  };

  return (
    <div>
      <FloatingWhatsApp 
        phoneNumber="521"
        accountName="Trayecto Empresarial"
        className="wa-style"
        statusMessage="En linea"
        avatar={ logoWhats }
        chatMessage="Hola, ¡Bienvenido a Trayecto Empresarial!. ¿Cómo podemos ayudarte?"
        allowClickAway
        notification={false}
      />

      <Navbar expand="lg" fixed="top" bg="light" data-bs-theme="light" className='navbar-custom blur'>
        <Container>
          <div>
            <img src={ logo } className='nav-image'/>
          </div>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav.Link onClick={ () => handleClick('refHome') }>
              <div className={ 'nav-selected' }>Inicio</div>
            </Nav.Link>
            <Nav.Link onClick={ () => handleClick('refPartners') }>
              <div className={ 'nav-selected' }>Socios</div>
            </Nav.Link>
            <Nav.Link onClick={ () => handleClick('refUs') }>
              <div className={ 'nav-selected' }>¿Quiénes Somos?</div>
            </Nav.Link>
            <Nav.Link onClick={ () => handleClick('refJoinUs') }>
              <div className={ 'nav-selected' }>¡Únete!</div>
            </Nav.Link>
            <Nav.Link onClick={ () => handleClick('refGallery') }>
              <div className={ 'nav-selected' }>Galería</div>
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Home /> } />
          <Route path="/conduccion" element={ <Home /> } /> 
        </Routes>
      </BrowserRouter>
      */}

      <div ref={ refHome }><Home handleClick={ handleClick }/></div>
      <div ref={ refPartners }><Partners /></div>
      <div ref={ refUs }><Us /></div>
      <div ref={ refJoinUs }><Suscribe /></div>
      <div ref={ refGallery }><Gallery /></div>

      <div className='footer'>
        <Container>
          <Row className="align-items-center">
            <Col md={ 4 }>
              <img src={ logo_b } className='nav-image'/>
            </Col>
            <Col md={ 4 } align="center">
              <div className='footer-text'> Aviso de Privacidad </div>
            </Col>
            <Col md={ 4 } align="right">
              <Facebook className='footer-icons' />
              <Instagram className='footer-icons' />
            </Col>
          </Row>
        </Container>
      </div>

    </div>
  );
}

export default App;
