import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import us_title from './img/us_title.png'
import us from './img/us.png'
import { Dot } from 'react-bootstrap-icons';

function Us() {
  return (
    <div className='section-big us-background'>
      <Container>
        <Row>
          <Col md={ 6 }>
          </Col>
          <Col md={ 6 } className="us-container">
            <Container>
              <Row>
                <Col>
                  <div className='section-container'>
                    <img src={ us_title } className='section-img' />
                    <br/><br/>
                    <div className='section-align-desc' align="right">
                      <div>Imagina un lugar donde tu red de contactos se expande exponencialmente, donde encuentras colaboradores, mentores y potenciales clientes. Trayecto Empresarial es ese lugar.</div>
                      <br/>
                      <div>¡Únete a nosotros y desbloquea nuevas oportunidades! Inscríbete ahora para formar parte de esta vibrante comunidad donde tu éxito se construye a través de conexiones significativas. ¡Conecta, colabora, crece! 🚀</div>
                      <br/>
                      <div>Generar más ingresos · Capacitaciones de alto nivel · Recomendaciones con compradores potenciales · Alianzas comerciales estratégicas · Crecimiento empresarial acelerado · Conectarte con empresarios calificados · Recuperar tu inversión a corto plazo</div>
                    </div>
                    <br/>
                    {/* <div className='section-align-desc text-right'>
                      <Container>
                        <Row>
                          <Col><span>Generar más ingresos</span></Col>
                          <Col md={ 'auto' }><Dot /></Col>
                        </Row>
                        <Row>
                          <Col><span>Capacitaciones de alto nivel</span></Col>
                          <Col md={ 'auto' }><Dot /></Col>
                        </Row>
                        <Row>
                          <Col><span>Recomendaciones con compradores potenciales</span></Col>
                          <Col md={ 'auto' }><Dot /></Col>
                        </Row>
                        <Row>
                          <Col><span>Alianzas comerciales estratégicas</span></Col>
                          <Col md={ 'auto' }><Dot /></Col>
                        </Row>
                        <Row>
                          <Col><span>Crecimiento empresarial acelerado</span></Col>
                          <Col md={ 'auto' }><Dot /></Col>
                        </Row>
                        <Row>
                          <Col><span>Conectarte con empresarios calificados</span></Col>
                          <Col md={ 'auto' }><Dot /></Col>
                        </Row>
                        <Row>
                          <Col><span>Recuperar tu inversión a corto plazo</span></Col>
                          <Col md={ 'auto' }><Dot /></Col>
                        </Row>
                      </Container>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Us;
