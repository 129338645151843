import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Marquee from "react-fast-marquee";

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import partners from './img/partners.png'

import viba from './img/partners/viba.png'
import pst from './img/partners/pst.png'
import pets from './img/partners/pets.png'
import nikken from './img/partners/nikken.png'
import lala from './img/partners/lala.png'
import hava from './img/partners/hava.png'
import grupooptico from './img/partners/grupooptico.png'
import eureka from './img/partners/eureka.png'
import conta from './img/partners/conta.png'
import ces from './img/partners/ces.png'
import carmen from './img/partners/carmen.png'
import capitalhumano from './img/partners/capitalhumano.png'
import atomy from './img/partners/atomy.png'
import almate from './img/partners/almate.png'
import aletse from './img/partners/aletse.png'

function Partners() {
  const socios = [ viba, pst, pets, nikken, lala, hava, grupooptico, eureka, conta, ces, carmen, capitalhumano, atomy, almate, aletse ]

  return (
    <div className='section'>
      <Container>
        <Row>
          <Col md={ 12 }>
            <div className='section-container'>
              <img src={ partners } className='section-img' />
              <br/><br/>
              <div align="center">
                <div className='section-desc text-center'>
                  Descubre una red diversa de talentos y especialistas en Trayecto Empresarial. Explora nuestro directorio para conectarte con profesionales que ofrecen servicios y productos de alta calidad.
                </div>
              </div>
              <br/><br/>
            </div>
          </Col>
          <Col>
            <Container>
              <Row>
                {/* socios.map( item => (
                  <Col md={ 6 } className='col-break'>
                    <div className='partner-container'>
                      <img src={ item } className='section-img' />
                    </div>
                  </Col>
                )) */}

                <Col md={ 12 }>
                  <Marquee>
                    { socios.map( item => (
                      <div>
                        <img src={ item } className='marquee-img' />
                      </div>
                    )) }
                  </Marquee>
                </Col>
                
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Partners;
