import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import { isMobile } from 'react-device-detect';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import suscribe_title from './img/suscribe-title.png'
import suscribe from './img/contact.png'

const API_PATH = 'https://digital-eureka.com/trayecto_mailer.php';
const SITE_KEY = "6LfXOmApAAAAAMUI9Ejipke2HR2VQ_mQWG0HHqjB";

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

function Suscribe() {
  const [path, setPath] = useState(window.location.pathname)
  const [ name, setName ] = useState(null)
  const [ email, setEmail ] = useState(null)
  const [ phone, setPhone ] = useState(null)
  const [ job, setJob ] = useState(null)
  const [ comments, setComments ] = useState(null)

  const [isLoading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data == "FAIL" || response.data == "ERROR") {
        setLoading(false)
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setLoading(false)
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setEmail("");
        setPhone("")
        setComments("");
      }

    }).catch(function(err) {
      setLoading(false)
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }
  
  return (
    <>
      <Modal show={showMessage} onHide={() => setShowMessage(false)}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={() => setShowMessage(false)}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      
      <div className='section-big suscribe-background'>
        <Container >
          <Row>
            <Col md={ 6 }>
              <Container>
                <Row>
                  <Col>
                    <div className='section-container'>
                      <img src={ suscribe_title } className='section-img' />
                      <br/><br/>
                      <div className='section-desc text-left'>
                        Completa el formulario a continuación y asegura tu lugar en esta sesión única. ¡No te pierdas la oportunidad de transformar tu trayectoria empresarial!
                        <Form id="contactForm" onSubmit={handleOnClick}>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label></Form.Label>
                            <Form.Control type="text" className='form-footer' name="name" placeholder="Nombre:" value={ name } onChange={() => setName()}/><br/>
                            <Form.Control type="email" className='form-footer' name="email" placeholder="Email:" value={ email } onChange={() => setEmail()}/><br/>
                            <Form.Control type="number" className='form-footer' name="phone" placeholder="Teléfono:" value={ phone } onChange={() => setPhone()}/><br/>
                            <Form.Control type="text" className='form-footer' name="business" placeholder="Giro de la empresa:" value={ job } onChange={() => setJob()}/>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" className='form-footer' name="comments" placeholder="Mensaje:" rows={3} value={ comments } onChange={() => setComments()}/>
                          </Form.Group>
                          <br/>
                          <div align='right'>
                            <Button  disabled={ isLoading } type='submit' className="action_button">Enviar</Button>{' '}
                          </div>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col md={ 6 }>
              { isMobile && 
                <div>
                  <img src={suscribe} className='section-img' />
                </div>
              }
              
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Suscribe;
