import { useState, useEffect, useRef } from 'react'
import PhotoAlbum from "react-photo-album";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import gallery from './img/galeria.png'

const HOST = 'https://trayectoempresarial.com.mx/'
//const HOST = 'http://localhost:3000/'

const photos = [
  {
    src: HOST + 'gallery/te1.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te2.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te3.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te4.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te5.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te6.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te7.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te8.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te9.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te10.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te11.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te12.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te13.jpg',
    width: 1000,
    height:  750,
  },
  {
    src: HOST + 'gallery/te14.jpg',
    width: 1000,
    height:  750,
  },
  
];

function Gallery() {
  return (
    <>
      <div className='section'>
        <Container>
          <Row>
            <Col md={ 12 }>
              <div> <img src={ gallery } className='section-img' /> </div>
              <br/>
              <PhotoAlbum layout="columns" photos={photos} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Gallery;
