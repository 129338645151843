import { isMobile } from 'react-device-detect';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import home_img from './img/home.png'
import home_img_title from './img/home_title.png'

function Home({ handleClick }) {
  return (
    <div className='section'>
      { isMobile && <><br/><br/></> }
      <Container className='main-container' >
        <Row>
          <Col style={ isMobile ? { padding: 0 } : {} }>
            <div className='section-container'>
              <img src={ home_img_title } className='section-img-title' />
              <img src={ home_img } className='section-img' />
            </div>
          </Col>
        </Row>
        <Row>
          <Col style={ isMobile ? { padding: 0 } : {} }>
            <Container>
              <Row>
                <Col align='center'>
                  <br/>
                  <div className='section-desc text-center'>
                    Sabemos que el camino del emprendedor puede ser desafiante, pero no tienes que recorrerlo solo. Nuestro Networking te ofrece la oportunidad de conectarte con una comunidad de mentes brillantes, dispuestas a compartir conocimientos, experiencias y oportunidades.
                  </div>
                  <br/>
                  <br/>
                  <div className="action_button" onClick={ () => handleClick('refJoinUs') }>¡Quiero asistir a una sesión!</div>
                  <br/>
                  <br/>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Home;
